@media (min-width: 768px) 
{
    .NavBarDropdownRightSide 
    {
        position: absolute;
        right: 6vw;
    }
    .NavBarCenter 
    {
        width: 80% !important;
    }
}

html
{
    font-size: calc(6.5px + (1vmin));
}

body
{
    &.background
    {
        background: url("./Assets/Images/Lego-Starwars-Background.png") fixed no-repeat;
        background-size: cover;
    }
}

hr
{
    &.white 
    {
        border-top: 1px solid rgba(255, 255, 255, 0.19) !important;
    }
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Sizing
.fitcontent 
{
    width: fit-content;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ React stuff
.React-logo
{
    @keyframes App-logo-spin 
    {
        from 
        {
            transform: rotate(0deg);
        }

        to 
        {
            transform: rotate(360deg);
        }
    }
    height: 40vmin;
    pointer-events: none;

    @media (prefers-reduced-motion: no-preference)
    {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-link
{
    color: #61dafb;
}

.ReactTemplate
{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Misc. Util
.TransparentWhenDisabled:disabled
{
    opacity: 0.35 !important;
}

.ShrinkAndVanishWhenDisabled
{
    transition: all 0.4s ease-in-out;

    &:disabled
    {
        transition: all 0.4s ease-in-out;
        transform: scale3d(0, 0, 0);
    }
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Nav configuration

// Cursor is pointer when hovering over home nav link. (Clickable nav brand to go home)
.PointerCursor
{
    cursor: pointer;
}

.NavLogo
{
    width: 6vmin;
}

.NavBrandName
{
    display: inline-block;
    vertical-align: middle;
}

.FixedNav
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
}

.NavBarCenter 
{
    align-items: center;
    width: 100%;
}

.AccountDropdown 
{
    > .nav-link:hover 
    {
        color: rgba(255, 255, 255, 0.75);
    }

    > .dropdown-toggle.btn
    {
        color: rgba(255, 255, 255, 0.5);
        background-color: initial;
        border-color: rgba(255, 255, 255, 0.5);
    }
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Footer configuration
.page-footer
{
    //background-color: rgba(52, 58, 64, 0.8);
    background-color: rgba(18, 3, 42, 0.8);
    color: white;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4vh;
    font-size: 8pt;
    display: flex;
    align-items: center;
    justify-content: center;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Wrapper configuration
.rounded-xl 
{
    border-radius: 0.6rem;
}

.bg-semi-transparent 
{
    background-color: rgba(0, 0, 0, 0.73);
}

.bg-white-semi-transparent
{
    background-color: rgba(255,255,255, 0.1);
}


// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Floating Label Configuration
.FloatingLabel
{
    color: white;
    font-size: 1.5rem !important;
    transition: all 0.5s ease-in-out;

    input
    {
        text-align: center;
        background-color: rgba(255, 255, 255, 0.05);
    }

    label
    {
        width: 100%;
        text-align: center;

        &.disabled
        {
            color: rgba(255, 165, 0, .5);
        }

		&.FloatingLabelEmpty
        {
            -webkit-transform: translateX(0) !important;
            transform: translateX(0) !important;
        }

		&.FloatingLabelNotEmpty
        {
            -webkit-transform: translate3d(15%, -70%, 0) scale(0.7) !important;
            transform: translate3d(15%, -70%, 0) scale(0.7) !important;
        }

        &.FloatingLabelFocus
        {
            color: rgba(255, 255, 255, 1);
            -webkit-transform: translate3d(15%, -70%, 0) scale(0.7) !important;
            transform: translate3d(15%, -70%, 0) scale(0.7) !important;
        }
    }

    &.disabled
    {
        transform: scale3d(0, 0, 0);
    }

}

.FloatingLabelTextArea
{
    transition: all 0.5s ease-in-out;

    div &
    {
        border-bottom: none !important;

        &.FloatingLabelNotEmpty
        {
            height: fit-content;
        }

        &.FloatingLabelEmpty
        {
            height: unset !important;
        }
    }

    textarea
    {
        padding-top: 0 !important;
        font-size: .75em !important;
        -webkit-transform: translate3d(0%, -2vh, 0) scale(1) !important;
        transform: translate3d(0%, -2vh, 0) scale(1) !important;
        transition: all 0.4s ease-in-out;
        height: 6vh;
        max-height: 30vh;

        &.FloatingLabelNotEmpty
        {
            height: 10vh;
        }

        &.FloatingLabelEmpty
        {
            height: 3vh !important;
        }
    }

    label
    {
        padding: 0 !important;
        position: relative;
        z-index: 100;

        &.FloatingLabelNotEmpty
        {
            -webkit-transform: translate3d(15%, 0%, 0) scale(0.7) !important;
            transform: translate3d(15%, 0%, 0) scale(0.7) !important;
        }

        &.FloatingLabelEmpty
        {
            -webkit-transform: translate3d(0%, 42%, 0) scale(1) !important;
            transform: translate3d(0%, 42%, 0) scale(1) !important;
        }

        &.FloatingLabelFocus
        {
            -webkit-transform: translate3d(15%, 0%, 0) scale(0.7) !important;
            transform: translate3d(15%, 0%, 0) scale(0.7) !important;
        }
    }
}

.FloatingLabelNumber
{
	label
    {
        padding: 0 !important;
        position: relative;
        z-index: 100;

        &.FloatingLabelNotEmpty
        {
            -webkit-transform:  translate3d(0%, 50%, 0) scale(0.7) !important;
            transform: translate3d(0%, 50%, 0) scale(0.7) !important;
        }

        &.FloatingLabelFocus
        {
            -webkit-transform:  translate3d(0%, 50%, 0) scale(0.7) !important;
            transform: translate3d(0%, 50%, 0) scale(0.7) !important;
        }
    }
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Bootstrap overrides

button.btn-close
{
	&.dark-shadow:focus
	{
		box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
	}
}

nav
{
	&.navbar
	{
		padding: 0.5rem 1rem !important;
	}
}

.btn-primary-override
{

    .btn-primary
    {
        outline: none;
        color: rgba(255, 255, 255, 0.5);
        background-color: rgba(255,255, 255, 0.05);
        border-color: rgba(255, 255, 255, 0.5);

        &.disabled, &:disabled
        {
            outline: none !important;
            color: rgba(255, 255, 255, 0.5) !important;
            background-color: rgba(255,255, 255, 0.05) !important;
            border-color: rgba(255, 255, 255, 0.5) !important;
        }

        &.focus, &:focus
        {
            outline: none;
            color: rgba(255, 255, 255, 0.5);
            background-color: rgba(255,255, 255, 0.05);
            border-color: rgba(255, 255, 255, 0.5);
            box-shadow: none !important;
        }

        &:not(:disabled):not(.disabled)
        {
            &.active,
            &:active,
            &:hover
            {
                color: rgba(255, 255, 255, 0.75) !important;
                background-color: rgba(255, 255, 255, 0.25) !important;
                border-color: rgba(255, 255, 255, 0.5) !important;
                box-shadow: initial !important;
            }
        }

        &:not(:disabled):not(.disabled)
        {
            &:active:focus,
            &.active:focus,
            show > &.dropdown-toggle:focus
            {
                box-shadow: none !important;
            }
        }
    }
}

.bg-dark-indigo
{
    background-color: #12032a !important;
}

.text-black
{
    color: black !important;
}

.text-black-white-outline
{
	-webkit-text-fill-color: black;
    -webkit-text-stroke-color: white;
    -webkit-text-stroke-width: 0.02em;
}

.dropdown-menu 
{
    background-color: #12032a !important;
    border-color: rgba(255, 255, 255, 0.5) !important;

    > .dropdown-item 
    {
        color: rgba(255, 255, 255, 0.5);

        &.active, &:active
        {
            color: rgba(255, 255, 255, 0.75);
        }
    }
}

.dropdown-item:hover 
{
    &:hover
    {
        background-color: rgba(255, 255, 255, 0.25) !important;
        color: rgba(255, 255, 255, 1) !important;
    }

    &:active, &.active
    {
        background-color: rgba(255, 255, 255, 0.15);
    }
}

.navbar-nav
{
    .navlink
    {
        &.show
        {
            color: rgba(255, 255, 255, 0.8);
        }

        &.active
        {
            color: rgba(255, 255, 255, 0.8);
        }
    }

    .nav-link:hover
    {
        color: rgba(255, 255, 255, 1);
    }
}

.text-center
{
	&.accordion-header
	{
		& .accordion-button
		{
			& span
			{
				margin-left: auto;
				margin-right: auto;
				text-align: center !important;
			}

			&::after
			{
				margin-left: 0;
			}
		}
	}
}

/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Alerts stuffs @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/

#AlertList
{
	position: absolute;
    height: 60vh;
	max-height: 60vh;
    width: 30vw;
    max-width: 30vw;
	padding-left: 1vw;
	padding-right: 1vw;
	overflow: hidden;

	&.Top
	{
		top: 5vw;
		& #AlertListPositionalWrapper
		{
			top: 0;
		}
	}

	&.Bottom
	{
		bottom: 5vw;
		& #AlertListPositionalWrapper
		{
			bottom: 0;
		}
	}

	&.Left
	{
		left: 1vw;
		& #AlertListPositionalWrapper
		{
			left: 0;
			& .alert
			{
				&.disabled
				{
					transform: translate(-40vw);
				}
			}
		}
	}

	&.Right
	{
		right: 1vw;
		& #AlertListPositionalWrapper
		{
			right: 1vw;
			& .alert
			{
				float: right;
				clear: both;
				&.disabled
				{
					transform: translate(40vw);
				}
			}
		}
	}

	& #AlertListPositionalWrapper
	{
		position: absolute;
		width: 92%;

		& .alert
		{
			max-width: fit-content;
			padding: 0 1vw;
			margin-top: 1vh;
			margin-bottom: 1vh;
			transition: all 0.75s ease;

			& p
			{
				margin: 0;
			}
		}
	}
}

/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Loading Screen stuff @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/


.progress-bar 
{
    transition: background-color .6s ease, width .6s ease;
}

.inline 
{ 
    display: inline;
}

.inline-block 
{ 
    display: inline-block;
}


/*
    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ SOCIAL STUFF
*/



#Socials
{
    padding-bottom: 1vh;
    padding-top: 1vh;
}


.TransitionImage
{
	-moz-transition: background-image .5s ease-in-out;
	-webkit-transition: background-image .5s ease-in-out;
	-o-transition: background-image .5s ease-in-out;
	transition: background-image .5s ease-in-out;
}

.SocialIcon
{
    height: 14ch;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1ch;
    margin-bottom: 1ch;
    background-position: center;
}


/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ DISCORD @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/

#DiscordIcon
{
    background-image: url("./Assets/Images/Icons/Discord/Black.png");

    &:hover
    {
	    background-image: url("./Assets/Images/Icons/Discord/Colourised.png");
    }
}



/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ TWITCH @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/

#TwitchIcon
{
    background-image: url("./Assets/Images/Icons/Twitch/Black.png");

    &:hover
    {
	    background-image: url("./Assets/Images/Icons/Twitch/Colourised.png");
    }
}

/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ YOUTUBE @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/


#YoutubeIcon
{
    background-image: url("./Assets/Images/Icons/Youtube/Black.png");

    &:hover
    {
	    background-image: url("./Assets/Images/Icons/Youtube/Colourised.png");
    }
}




/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ YOUTUBE @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/


#TwitterIcon
{
    background-image: url("./Assets/Images/Icons/Twitter/Black.png");

    &:hover
    {
	    background-image: url("./Assets/Images/Icons/Twitter/Colourised.png");
    }
}



/*@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ YOUTUBE @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*/


#FacebookIcon
{
    background-image: url("./Assets/Images/Icons/Facebook/Black.png");
    &:hover
    {
	    background-image: url("./Assets/Images/Icons/Facebook/Colourised.png");
    }
}